import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from "../Media/BANNERS/MAFM.png";
import MobileBanner from "../Media/BANNERS/Mobile/MAFA.png";

function MusicAndFineArts(){

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return(
        <>
        <Header />
        {shouldShowMobile ? (
                <div className="container">
                        <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
              <img src={Banner} className="card-img-top" alt="..." />
            )}
        <div className="container centered-text">
        <br />
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <p>
                            The Music and Fine Arts Ministry (MFAM) seeks to equip, empower and mobilize those who have been gifted by God to enhance the worship experience. The focus of the MFAM is God Himself. Our vision is to build worship teams, leaders, bands, dancers, actors, singers who have a share desire to worship God and to edify His people.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <p>
                            Keep an eye on our events page to see upcoming events for music and arts of The Chosen Vessel.
                        </p>
                    </div>
                </div>
            
        </div>
        <Footer />
        </>
    )
}

export default MusicAndFineArts;