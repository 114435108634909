import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Youtube } from 'react-bootstrap-icons';
import logo from '../Media/CVLogo.png';

function Footer() {
    const logoStyle = {
        width: '4rem',
        height: '4rem',
    };
    
    return (
        <footer className="text-center text-white" style={{ backgroundColor: 'black' }}>
            <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <h3 style={{ color: 'white' }}>About</h3>
                            <Link to="/" className="navbar-brand">
                                <img src={logo} style={logoStyle} alt="Logo" />
                            </Link>
                        </div>
                        <div className="col">
                            <h3 style={{ color: 'white' }}>Follow Us</h3>
                            <Link to="https://www.facebook.com/The-Official-Chosen-Vessel-Cathedral-TCVC-493735950682176"><Facebook className="mx-2" style={{ color: 'white' }}/></Link>
                            <Link to="https://instagram.com/chosenvessel_tcvc"><Instagram className="mx-2" style={{ color: 'white' }}/></Link>
                            <Link to="https://www.youtube.com/channel/UCwRxGy0RvPbd9PLJlVQYhtg"><Youtube className="mx-2" style={{ color: 'white' }}/></Link>
                            <div className='dflex justify-content-center'>
                            <br />
                        </div>
                        </div>
                        <div className="col">
                          <h3 style={{ color: 'white' }}>Contact Us</h3>
                          <p style={{ color: 'white' }}>4650 Campus Drive • Fort Worth, TX 76119</p>
                          <p style={{ color: 'white' }}>Email: info@thechosenvessel.org</p>
                          <p style={{ color: 'white' }}>Phone: (817) 413-9849</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
