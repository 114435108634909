import Countdown from "../Components/Countdown";
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";
import Main from "../Landing/Main";

function Landing() {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    )
}

export default Landing;
