import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Media/CVLogo.png';
import Countdown from './Countdown';

function Navigation() {
    const navBarFont = {
        font: 'arial'
    };

    const logoStyle = {
        width: '3rem', // Adjust the width and height as needed
        height: '3rem',
    };

    return (
        <div>
            <div>
                <Countdown />
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container">
                    <Link to="/home" className="navbar-brand">
                        <img src={logo} style={logoStyle} alt="Logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/home" className="nav-link text-white" >Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/give" className="nav-link text-white">Give</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/events" className="nav-link text-white">Events</Link>
                            </li>
                            {/* <li className="nav-item">
                            <Link to="/store" className="nav-link text-white" disabled>Store</Link>
                        </li> */}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-white" href="/contact" id="memberResourcesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Member Resources
                                </a>
                                <ul className="dropdown-menu text-white" aria-labelledby="memberResourcesDropdown" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    {/* <li><Link to="/membership-resources/Ministerial-Alliance" className="nav-link text-white">Ministerial Alliance</Link></li> */}
                                    {/* <li><Link to="/membership-resources/Giving-Statement-Request" className="nav-link text-white">Giving Statement Request</Link></li> */}
                                    <li><Link to="/membership-resources/Volunteer" className="nav-link text-white">Volunteer</Link></li>
                                    <li><Link to="/membership-resources/Strengthen-Your-Relationship" className="nav-link text-white">Strengthen Your Relationship</Link></li>
                                    {/* <li><Link to="/membership-resources/image-awards" className="nav-link text-white">Image Awards Voting</Link></li> */}
                                </ul>
                            </li>

                            <li className="nav-item dropdown text-white">
                                <a className="nav-link dropdown-toggle text-white" href="#" id="ministriesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Ministries
                                </a>
                                <ul className="dropdown-menu text-white" aria-labelledby="ministriesDropdown" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <li><Link to="/ministries/Chosen-Vessel-Everywhere" className="nav-link text-white">Chosen Vessel Everywhere</Link></li>
                                    <li><Link to="/ministries/MoMENtum" className="nav-link text-white">MoMENtum</Link></li>
                                    <li><Link to="/ministries/MusicAndFineArts" className="nav-link text-white">Music and Fine Arts</Link></li>
                                    <li><Link to="/ministries/SundaySchool" className="nav-link text-white">Sunday School</Link></li>
                                    <li><Link to="/ministries/SacredHearts" className="nav-link text-white">Silver Saints</Link></li>
                                    <li><Link to="/ministries/RefresHER" className="nav-link text-white">RefresHER</Link></li>
                                    <li><Link to="/ministries/ChosenYouth" className="nav-link text-white">Chosen Youth</Link></li>
                                    <li><Link to="/ministries/CONNEX" className="nav-link text-white">CONNEX</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-white" href="/contact" id="memberResourcesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Contact
                                </a>
                                <ul className="dropdown-menu text-white" aria-labelledby="memberResourcesDropdown" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <li><Link to="/contact" className="nav-link text-white">Contact</Link></li>
                                    <li><Link to="/contact/scam-alert" className="nav-link text-white">Scam Alert</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/become-a-member" className="nav-link text-white">Become A Member</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-white" href="/contact" id="memberResourcesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About
                                </a>
                                <ul className="dropdown-menu text-white" aria-labelledby="memberResourcesDropdown" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <li><Link to="/about/About-Us" className="nav-link text-white">About Us</Link></li>
                                    <li><Link to="/about/Meet-Our-Pastor" className="nav-link text-white">Meet Our Pastor</Link></li>
                                    <li><Link to="/about/Our-People" className="nav-link text-white">Our People</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navigation;
