import React from "react";
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";
import Banner from "../Media/Upcoming Events.png";
import MobileBanner from "../Media/Upcoming.png";
import { Easel, Pass } from "react-bootstrap-icons";
import eventCards from "../Components/MasterEvents";

function AllEvents() {
    const cardStyle = {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0)",
        border: "none",
        marginBottom: "20px",
    };

    const screenWidth = window.innerWidth;
    const thresholdWidth = 640;
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}

            <div className="d-flex justify-content-center">
                <div className="col-sm-6">
                    <ul className="list-group">
                        {eventCards.map((card, index) => (
                            <li className="list-group-item mb-3" key={index} style={cardStyle}>
                                <div className="row align-items-center">
                                    {card.imgSrc && (
                                        <div className="col-md-4">
                                            <a href={card.link}>
                                                <img
                                                    className="img-fluid"
                                                    src={card.imgSrc}
                                                    alt="Card image cap"
                                                />
                                            </a>
                                        </div>
                                    )}
                                    <div className={card.imgSrc ? "col-md-8" : "col-md-12"}>
                                        <div className="card-body">
                                            {card.title && <h5 className="card-title">{card.title}</h5>}
                                            {card.text && <p className="card-text">{card.text}</p>}
                                            {card.eventDescription && (
                                                <p className="card-text">
                                                    <small className="card-text">{card.eventDescription}</small>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AllEvents;
