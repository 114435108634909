import React from "react"; // Make sure to import React
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from "../Media/BANNERS/StrengthenYourRelationship.png";
import MobileBanner from "../Media/BANNERS/Mobile/StrengthenMobile.png";

function StrengthenYourRelationship() {

        // Get the current width of the viewport
        const screenWidth = window.innerWidth;

        // Define a threshold width (540 in this case)
        const thresholdWidth = 640;
    
        // Determine whether to show the intro or slideshow based on screen width
        const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <div>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <div className="d-flex justify-content-center">
                <div className="col-md-auto">
                    <iframe src="https://thechosenvesselchurch.breezechms.com/form/6ea047" width="700" height="1000" border="1px solid #ccc" scrolling='no'></iframe>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default StrengthenYourRelationship;
