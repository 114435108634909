import React, { useState, useEffect } from 'react';
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";
import BRB from "../Media/beRightBack.mp4";
import BRBMobile from "../Media/BRBMobile.mp4";

function LiveStream() {

    // Define the target date for Sunday and Tuesday in Central Daylight Time (CDT)
    const now = new Date();
    const cdtOffset = -5 * 60; // Central Daylight Time (CDT) offset in minutes (-5 hours)
    const sundayTarget = new Date(now);
    sundayTarget.setUTCHours(11 - cdtOffset / 60, 0, 0, 0); // 11:00 AM CDT
    sundayTarget.setDate(sundayTarget.getUTCDate() + ((7 - sundayTarget.getUTCDay()) % 7));

    const tuesdayTarget = new Date(now);
    tuesdayTarget.setUTCHours(19 - cdtOffset / 60, 30, 0, 0); // 7:30 PM CDT
    if (now.getUTCHours() >= 19 || (now.getUTCHours() === 19 && now.getUTCMinutes() >= 30)) {
        // If it's already past 7:30 PM CDT today, set the target for the next Tuesday
        tuesdayTarget.setDate(tuesdayTarget.getUTCDate() + ((9 - tuesdayTarget.getUTCDay() + 7) % 7));
    } else {
        // If it's before 7:30 PM CDT today, set the target for today
        tuesdayTarget.setDate(tuesdayTarget.getUTCDate() + ((2 - tuesdayTarget.getUTCDay() + 7) % 7));
    }

    // Initialize the state for remaining time
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

    // Calculate the remaining time until the next target date
    function calculateRemainingTime() {
        const now = new Date();
        let timeUntilSunday = sundayTarget - now;
        let timeUntilTuesday = tuesdayTarget - now;

        // If the target time is in the past, add 7 days to it
        if (timeUntilSunday < 0) {
            timeUntilSunday += 7 * 24 * 60 * 60 * 1000;
        }
        if (timeUntilTuesday < 0) {
            timeUntilTuesday += 7 * 24 * 60 * 60 * 1000;
        }

        return Math.min(timeUntilSunday, timeUntilTuesday);
    }

    // Update the remaining time every second
    useEffect(() => {
        const timerInterval = setInterval(() => {
            setRemainingTime(calculateRemainingTime());
        }, 1000);

        // Cleanup the interval on unmount
        return () => clearInterval(timerInterval);
    }, []);

    const isSunday = now.getUTCDay() === 0;
    // Check if the event is currently live
    const eventIsLive = isSunday && now >= sundayTarget && now <= tuesdayTarget;

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (640 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowIntro = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            <div className='container-sm'>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                        src="https://player.restream.io/?token=1f00367b6a01417e85bb479d7f94cf94&vwrs=1"
                        allow="autoplay"
                        allowFullScreen
                        frameBorder="0"
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                    ></iframe>
                </div>

            </div>
            <Footer />
        </>
    );
}



export default LiveStream;
