import React from 'react';
import '../index.css';
import mobileIntro from '../Media/MobileVideo.mp4';

function MobileIntro() {
    
    const videoStyle = {
        opacity: 0.75, // Set your desired opacity value here (0.5 is 50%)
      };
    
    return (
        <div className="slideshow-container">
      <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="9000">
            <div className="video-container">
              <video className="centered-video" autoPlay loop muted playsInline style={videoStyle}>
                <source src={mobileIntro} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          {/* Add more carousel items with videos or images as needed */}
        </div>
      </div>
    </div>
    );
}

export default MobileIntro;
