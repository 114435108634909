import React from 'react';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';
import Banner from '../Media/BANNERS/Giving.png';
import MobileBanner from "../Media/BANNERS/Mobile/GivingMobileBanner.png";


function GivingStatementRequest() {
    const inputStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '8px',
        width: '100%'
    };

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />
            <div className="d-flex justify-content-center">
                <div className='d-flex justify-content-center'>
                    <div className='col-lg'>
                        <iframe src="https://thechosenvesselchurch.breezechms.com/form/0d7c7a" width="700" height="900" border= "1px solid #ccc" scrolling='no'></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default GivingStatementRequest;
