import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from "../Media/MoMENtumBanner.png";
import MobileBanner from "../Media/BANNERS/Mobile/momentum.png";

function MoMENtum() {

    const inputStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        border: 'none',  // Remove border
        borderRadius: '5px', // Add border radius for aesthetics
        padding: '8px', // Add padding for better spacing
        width: '100%' // Make the inputs span the entire width
    };


    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />

            <div className='d-flex justify-content-center'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center'>
                        <p>
                            Welcome to MoMENtum, a dynamic men's ministry committed to igniting the fire of purpose, passion, and perseverance in the hearts of men. Inspired by the timeless wisdom of 1 Corinthians 15:58, our ministry is a thriving community of men who understand that their lives are a unique journey, filled with moments of significance waiting to be seized.
                        </p>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center'>
                        <p>
                            In a world that often demands men to be strong and immovable, MoMENtum provides a safe haven where men can explore their faith, cultivate meaningful relationships, and grow in their devotion to the Lord. We believe that every action, every decision, and every encounter can be a catalyst for God's work, and we are here to empower men to live that truth.
                        </p>
                    </div>
                </div>
            </div>

            <br />

            <div className='d-flex justify-content-center'>
                <div className='col-lg-4'>
                    <div className='d-flex justify-content-center'>
                        <h3 style={{ color: "white" }}><b>Our Mission:</b></h3>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center'>
                        <p>
                            At MoMENtum, our mission is clear: to empower men to live passionately for the Lord, anchored in their faith, and engaged in a purposeful life. We understand that the journey of faith can be challenging, but it's the very essence of these challenges that molds us into the men God has destined us to be. We embrace our roles as sons, brothers, husbands, fathers, and friends, recognizing that our influence is a powerful force for good in this world.
                        </p>
                    </div>
                </div>
            </div>

            <br />

            <div className='d-flex justify-content-center'>
                <div className='col-lg-4'>
                    <div className='d-flex justify-content-center'>
                        <h3 style={{ color: "white" }}><b>What We Offer:</b></h3>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='col-lg-4'>
                    <div className='d-flex justify-content-center'>
                        <ul style={{ color: "white" }}>
                            <li>
                                <b>Fellowship</b> MoMENtum is a brotherhood where genuine friendships are forged. We gather regularly to share life's joys and sorrows, providing support and encouragement in our walk of faith.
                            </li>
                            <li>
                                <b>Bible Study</b> Through in-depth studies of the Word of God, we equip men with the knowledge and wisdom needed to navigate life's complexities and challenges.
                            </li>
                            <li>
                                <b>Mentorship</b> Seasoned mentors are available to guide and inspire the younger generation, sharing their experiences and insights to help them thrive in their faith and life.
                            </li>
                            <li>
                                <b>Service</b> We believe that faith without action is incomplete. MoMENtum encourages men to actively serve their communities and contribute to positive change.
                            </li>
                            <li>
                                <b>Events and Retreats</b> We host events and retreats that provide opportunities for spiritual renewal, personal growth, and a deeper connection with God.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <br />

            <div className='d-flex justify-content-center'>
                <div className='col-lg-4'>
                    <div className='d-flex justify-content-center'>
                        <h3 style={{ color: "white" }}><b>Join Us Today:</b></h3>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center'>
                        <p>
                            If you're a man seeking to live with purpose, passion, and perseverance, MoMENtum welcomes you with open arms. Let us journey together, embracing the challenges and triumphs that come our way, all while working enthusiastically for the Lord. Together, we will discover that nothing we do for the Lord is ever useless, and our lives will radiate the MoMENtum of God's love and grace.
                        </p>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center'>
                        <p>
                            Join us in this exciting journey of faith, brotherhood, and spiritual growth. MoMENtum is not just a ministry; it's a movement, and we invite you to be a part of it. Together, we will rise, unshaken, in pursuit of God's purpose for our lives.
                        </p>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <div className="col-md-auto">
                    <iframe src="https://thechosenvesselchurch.breezechms.com/form/572ddb86" width="700" height="750" border="1px solid #ccc" scrolling='no'></iframe>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MoMENtum;