import Footer from "../Landing/Footer";
import Header from "../Landing/Header";

function Give(){
    return(
        <>
        <Header />
        <iframe
                src="https://www.givelify.com/donate/the-chosen-vessel-church-fort-worth-tx-2j7wy5NzQzNQ==/donation/amount"
                width="100%"
                height="790px"
                style={{
                    border: '0',
                    background: 'black',
                }}
                title="Givelify"
            ></iframe>
        <Footer />
        </>
    )
}

export default Give;