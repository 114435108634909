import Header from "../Landing/Header";
import Footer from "../Landing/Footer";


function NewMembers() {
    return (
        <>
            <Header />
            <iframe
                src="https://thechosenvesselchurch.breezechms.com/form/d7b2eb14267"
                width="100%"
                height="1000px"
                style={{
                    border: '0',
                    background: 'black',
                }}
                title="New Members Class"
            ></iframe>
            <Footer />
        </>
    )
}

export default NewMembers;
