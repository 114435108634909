import Header from "../Landing/Header";
import Footer from "../Landing/Footer";


function ConnexChristmas() {
    return (
        <>
            <Header />
            <iframe
                src="https://thechosenvesselchurch.breezechms.com/form/af6569"
                width="100%"
                height="1000px"
                style={{
                    border: '0',
                    background: 'black',
                }}
                title="Connex Christmas"
            ></iframe>
            <Footer />
        </>
    )
}

export default ConnexChristmas;
