import React from 'react';
import eventCards from './MasterEvents';

function MobileEvents() {
  const initialEvents = eventCards.slice(0, 2); // Extracting the first three events
  return (
    <>
      <hr />
      <div className="container">
        <div className="row">
          {initialEvents.map((event, index) => (
            <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-8 col-xs-offset-2" key={index}>
              {/* Fluid width widget */}
              <div className="panel panel-danger">
                <div className="panel-heading">
                </div>
                <div className="panel-body">
                  <ul className="media-list">

                    <li className="media">
                      <div className="media-left">
                        <div className="panel panel-danger text-center date">
                          <div className="panel-heading month">
                            <span className="panel-title strong all-caps">{event.month}</span>
                          </div>
                          <div className="panel-body">{event.day}</div>
                        </div>
                      </div>
                      <div className="media-body">
                        <a href="/Events">
                          <h4 className="media-heading">{event.title}</h4>
                        </a>
                        <p>{event.eventDescription}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End fluid width widget */}
            </div>
          ))}
        </div>
      </div>
      <a href="/events" className="btn custom-bg-color btn-block rounded-pill">
        <b>See More Events</b>
      </a>
    </>
  );
}

export default MobileEvents;
