import React, { useState } from "react";
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";

function LeadershipPortal() {
  // Define state to manage the active tab
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Header />
      <div className="card text-center">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
                href="#"
                onClick={() => handleTabChange("tab1")}
              >
                Event Form
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          {/* Render content based on activeTab */}
          {activeTab === "tab1" && (
            <p className="card-text"><div className="row">
            <div className="d-flex justify-content-center">
              <div className="col-md-10">
                <div className="d-flex justify-content-center">
                  <iframe src="https://thechosenvesselchurch.breezechms.com/form/1ee74b" width="700" height="750" border="1px solid #ccc" scrolling='no'></iframe>
                </div>
              </div>
            </div>
          </div></p>
          )}
          {activeTab === "tab2" && (
            <p className="card-text">Content for Tab 2</p>
          )}
          {activeTab === "tab3" && (
            <p className="card-text">Content for Tab 3</p>
          )}
          <a href="#" className="btn btn-primary">
            Go somewhere
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LeadershipPortal;
