import React from "react";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";

function ImageAwards(){
    return(
        <>
        <Header />
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfJ1ysO3PoV-IFe5_DpCVTIX5rzkkuEvvahQbDPaqIL6Vwo8Q/viewform"
                width="100%"
                height="790px"
                style={{
                    border: '0',
                    background: 'black',
                }}
                title="Google Form"
            ></iframe>
            <Footer />
        </>
    )
}

export default ImageAwards;