import flyer from "../Media/RefresHER Conference 2024.jpg"

function MajorEvent() {
    return (
        <>
            <div className='col-lg'>
                <div className="card transparent-card">
                    <div className="row">
                        <div class="d-flex align-items-center">

                            <div className="row"> {/* Define the width of the image column */}
                                <div class="d-flex align-items-center">
                                    <img className="card-img-top" src={flyer} alt="Card image cap" />
                                </div>
                            </div>

                            <div className="row"> {/* Define the width of the description column */}
                                <div className="card-body">
                                    <h3 className="card-title">RefresHER Womens Conference</h3>
                                    <div className='d-flex justify-content-center'>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                            The Chosen Vessel, 4650 Campus Dr, Fort Worth, TX 76119, USA
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                        Friday - Pastor Sheryl Brady, Saturday - Brunch @ Tiffany's with Laterras Whitfield (Dear Future wifey), Sunday Evangelist Latrice Ryan
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <a href="https://brushfire.com/tcvc/refresher/572745" className="btn custom-bg-color btn-block rounded-pill">Register Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MajorEvent;