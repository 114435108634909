import React from "react";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import PKH from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Keith Hall - Executive Pastor.jpg";
import PEB from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Erick Bowens - Chief Ministry Liaison _ Youth and Young Adult Pastor.jpg";
import PJH from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Jon Hatcher - Chosen Vessel Everywhere _ Ministerial Alliance.jpg";
import PRF from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Rodney Fleming - First Impressions.jpg";
import PSG from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Shirley Gardner - Congregational Care.jpg";
import PCR from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Charles Rainbow - Intake Christian Education.jpg";
import PPS from "../Media/TCVC 2023-2024 CMS Headshots/Pastor Paulette Smith - Helps Ministry.jpg"
import SJ from "../Media/TCVC 2023-2024 CMS Headshots/Shilantha Jones - Church Administrator_CFO (Chief Financial Officer).jpg"
import DC from "../Media/TCVC 2023-2024 CMS Headshots/Deacon Isaac Cooper - Deacons Ministry.jpg"
import OD from "../Media/TCVC 2023-2024 CMS Headshots/Otis Dunn - MoMENtum Men’s Ministry.jpg";
import DVB from "../Media/TCVC 2023-2024 CMS Headshots/Deacon Vincent Benn - MoMENtum Men’s Ministry.jpg";
import KR from "../Media/TCVC 2023-2024 CMS Headshots/Katrice Reed - RefresHer Women’s Ministry.jpg";
import DANS from "../Media/TCVC 2023-2024 CMS Headshots/Deacon Dwayne _ Ann Smith - Married Couples Ministry.jpg";
import JA from "../Media/TCVC 2023-2024 CMS Headshots/Jonathan Alexander - Culinary Ministry.jpg";
import VB from "../Media/TCVC 2023-2024 CMS Headshots/Voneca Bolden - Culinary Ministry.jpg";
import KG from "../Media/TCVC 2023-2024 CMS Headshots/Kendric Gray - Media Ministry.jpg";
import QD from "../Media/TCVC 2023-2024 CMS Headshots/Quanetta Dunn - Campus Enhancement.jpg";
import JB from "../Media/TCVC 2023-2024 CMS Headshots/Jamal Bolden, Sr. Campus Enhancement.jpg";
import JasB from "../Media/TCVC 2023-2024 CMS Headshots/Jasmine Bowens - Chosen Youth.jpg";
import LE from "../Media/TCVC 2023-2024 CMS Headshots/LaShawna Estis - Chosen Youth.jpg";
import WM from "../Media/TCVC 2023-2024 CMS Headshots/Whitney McDonald - Dance Ministry.jpg";
import KC from "../Media/TCVC 2023-2024 CMS Headshots/Katina Clay - Membership Services _ Greeters _ Volunteers.jpg";
import FF from "../Media/TCVC 2023-2024 CMS Headshots/Felicia Fuller - ACTS Ministry.jpg";
import JoD from "../Media/TCVC 2023-2024 CMS Headshots/Johnnie Durham - Silver Saints (Seniors).jpg";
import RW from "../Media/TCVC 2023-2024 CMS Headshots/Rocille Willingham - Valet_Brother Servants.jpg";
import CW from "../Media/TCVC 2023-2024 CMS Headshots/Claude Watkins - Brother Servants.jpg";
import FH from "../Media/TCVC 2023-2024 CMS Headshots/Fay Holbert - Health _ Wellness (ICARE).jpg";
import MSJ from "../Media/TCVC 2023-2024 CMS Headshots/Marvin Sapp, Jr. - Social Media, Marketing _ Communications.jpg";
import Banner from "../Media/BANNERS/meetOurPeople.png"
import MobileBanner from "../Media/BANNERS/Mobile/meetOurPeople.png"


function OurPastoralStaff() {

        // Get the current width of the viewport
        const screenWidth = window.innerWidth;

        // Define a threshold width (540 in this case)
        const thresholdWidth = 640;
    
        // Determine whether to show the intro or slideshow based on screen width
        const shouldShowMobile = screenWidth < thresholdWidth;

    return (
            <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                        <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
              <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PKH} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Executive Pastor</h6>
                                <p class="card-text">Pastor Keith Hall</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PEB} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Chief Ministry Liaison & Youth and Young Adult Pastor</h6>
                                <p class="card-text">Pastor Erick Bowens</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PJH} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Ministry: Chosen Vessel Everywhere & Ministerial Alliance</h6>
                                <p class="card-text">Chief Ministry Servant: Pastor Jon Hatcher</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PRF} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Ministry: First Impressions</h6>
                                <p class="card-text">Chief Ministry Servant: Pastor Rodney Fleming</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PSG} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Ministry: Congregational Care</h6>
                                <p class="card-text">Chief Ministry Servant: Pastor Shirley Gardner</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PCR} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Intake Christian Education (New Members Class & Sunday School)</h6>
                                <p class="card-text">Chief Ministry Servant: Pastor Charles Rainbow</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src="..." style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Ministry: Worship & Arts</h6>
                                <p class="card-text">Chief Ministry Servant: Pastor Myron Williams</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={PPS} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Ministry: Helps Ministry</h6>
                                <p class="card-text">Chief Ministry Servant: Pastor Paulette Smith</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={MSJ} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Social Media, Marketing & Communications</h6>
                                <p class="card-text">Chief Ministry Servant: Marvin Sapp Jr</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={DC} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Deacons Ministry</h6>
                                <p class="card-text">Chief Ministry Servant: Deacon Isaac Cooper</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={OD} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>MoMENtum Men's Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Otis Dunn </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={DVB} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>MoMENtum Men's Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Deacon Vincent Ben</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={KR} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>RefresHER Women's Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Katrice Reed</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src="..." style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Ministry: MoMENtum</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Kiya Pugh</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src="..." style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Married Couples Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servants: J.R. and Tanika Morgan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={DANS} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Married Couples Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servants: Deacon Dwayne & Ann Smith</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={JA} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Culinary Arts Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Jonathan Alexander</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={VB} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Culinary Arts Ministry</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Voneca Bolden</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={KG} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Media Ministry</h6>
                                <p class="card-text">Chief Ministry Servant: Kendric Gray</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={QD} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Campus Enhancement</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Quenetta Dunn</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={JB} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Campus Enhancement</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Jamal Bolden</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={WM} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Dance Ministry</h6>
                                <p class="card-text">Chief Ministry Servant: Whitney McDonald</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={JasB} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Chosen Youth</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Jasmine Bowens</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={LE} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Chosen Youth</h6>
                                <p class="card-text">Co-Chief Ministry Servant: LaShawna Estis</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src="..." style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Connex Young Adult Ministry</h6>
                                <p class="card-text">Chief Ministry Servant: Tristain Williams</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={RW} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Valet/Brother Servants</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Rocille Willingham</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={CW} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Valet/Brother Servants</h6>
                                <p class="card-text">Co-Chief Ministry Servant: Claude Watkins</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={KC} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Membership Services</h6>
                                <p class="card-text">Chief Ministry Servant: Katina Clay</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={FF} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>ACTS Ministry</h6>
                                <p class="card-text">Chief Ministry Servant: Felicia Fuller</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={JoD} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Sacred Hearts</h6>
                                <p class="card-text">Chief Ministry Servant: Johnnie Durham</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    <div className="col-sm">
                        <div class="card custom-card" style={{ width: "18rem" }}>
                            <div className="d-flex justify-content-center">
                                <img class="card-img-top" src={FH} style={{ width: "70%", height: "70%" }} alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign: "center" }}>Health & Wellness</h6>
                                <p class="card-text">Chief Ministry Servant: Fay Holbert</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default OurPastoralStaff;