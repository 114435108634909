import React from 'react';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';
import Banner from "../Media/BANNERS/ministerialAlliance.png";
import MobileBanner from "../Media/BANNERS/Mobile/ministerialAlliance.png";

function MinisterialAlliance() {

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />
            <div className='container'>
                <video controls width="100%" height="auto">
                    <source src="https://video.wixstatic.com/video/1df946_c1fcd42a3f9b4165a87d1e2c92282456/1080p/mp4/file.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <Footer />
        </>
    );
}

export default MinisterialAlliance;
