import React from "react";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";

function ScamAlert(){
    return(
        <>
        <Header />
        <div className="d-flex flex-column align-items-center">
            <div className="row">
                <div className="d-flex flex-column align-items-center">
                <h1>Scam Alert</h1>
                <h2 style={{ color: 'red' }}>Scam Alert (Please Read)</h2>
                    <div className="container centered-text">
                        <p>
                        Bishop Marvin L. Sapp has been the subject of impersonators and fake social media pages for almost a decade. It is a problem that literally spreads like a wildfire - we shut one down and five more pop up. 
                        Our office is in frequent contact with executives at social media platforms to take down fake pages. Unfortunately, technology has made it easier for scam artists to create elaborate schemes to prey on innocent, unsuspecting people. It causes Bishop Sapp and our office great stress that scammers take such broad measures to impersonate Bishop Sapp. It is equally distressing that you may have been victimized by these people.
                        </p>
                        <p>
                        Bishop Marvin L. Sapp does not contact anyone directly FOR ANY REASON on social media through direct messages, text message, phone calls, emails, or otherwise. If you receive any communication at all on his behalf or have been contacted FOR ANY REASON, it is an impersonator and you should not respond, reply or provide any of your personal information. If someone told you that they need your financial or other information so that someone can send you money (for example, including and not limited to fake checks) from Bishop Marvin L. Sapp - it is a scam.
                        If you are a victim of a social media, email, or phone scams, take the following steps:
                        </p>
                        <ul className="no-bullet-points">
                            <br />
                            <p>
                                Stop all contact with the scam artist and block his or her phone numbers, direct messages, and email addresses.
                            </p>
                            <br />
                            <li style={{ color: 'white' }}>
                                Keep copies of all communications.
                            </li>
                            <li style={{ color: 'white' }}>
                                Report the matter to the social media platform's website. (Ex. Facebook, Twitter, Instagram, etc.)
                            </li>
                            <li style={{ color: 'white' }}>
                                Report the matter to your local police department.
                            </li>
                            <li style={{ color: 'white' }}>
                                Report the matter to the Federal Bureau of Investigation’s Internet Crime Complaint Center online <a className="red-link" href="https://www.ic3.gov/complaint/default.aspx/">here</a>
                            </li>
                            <br />
                            <li style={{ color: 'white' }}>
                                Report the matter to the Federal Trade Commission as follows:
                            </li>
                            <p>
                                Federal Trade Commission
                            </p>
                            <p>
                                Consumer Response Center
                            </p>
                            <p>
                                600 Pennsylvania Avenue NW
                            </p>
                            <p>
                                Washington, DC 20580
                            </p>
                            <p>
                                (877) 382-4357 or TTY: (866) 653-4261
                            </p>
                            <p>
                                <a className="red-link" href="https://www.ftc.gov/media/71268">www.ftc.gov/media/71268</a>
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default ScamAlert;