import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './View/Landing';
import Contact from './View/Contact';
import ScamAlert from './View/ScamAlert';
import MinisterialAlliance from './View/MinisterialAlliance';
import GivingStatementRequest from './View/GivingStatementRequest';
import Volunteer from './View/Volunteer';
import Give from './View/Give';
import AboutUs from './View/AboutUs';
import MeetOurPastor from './View/MeetOurPastor';
import OurPastoralStaff from './View/OurPastoralStaff';
import ImageAwards from './View/ImageAwards';
import ChosenVesselEverywhere from './View/ChosenVesselEverywhere';
import MusicAndFineArts from './View/MusicAndFineArts';
import SundaySchool from './View/SundaySchool';
import ChosenYouth from './View/ChosenYouth';
import Connex from './View/Connex';
import RefresHER from './View/RefresHER';
import SacredHearts from './View/SacredHearts';
import MoMENtum from './View/MoMENtum';
import LiveStream from './View/LiveStream';
import Store from './View/Store';
import NotFound from './View/NotFound';
import AllEvents from './View/AllEvents';
import BecomeAMember from "./View/BecomeAMember";
import LeadershipPortal from './View/LeadershipPortal';
import StrengthenYourRelationship from './View/StrengthenYourRelationship';
import NewMembers from './View/NewMembers';
import MarriedCouples from './View/MarriedCouples';
import ConnexChristmas from './View/ConnexChristmas';
import HolyConsecration from './View/HolyConsecration';
import MinisterialTraining from './Forms/MinisterialTraining';
import ScamAlertGreeting from './View/ScamAlertGreeting';
import InnovateToActivate from './Forms/InnovateToActivate';
import EmpowermentSundays from './Forms/EmpowermentSundays';
import BabyDedication from './View/Baby';
import GradSunday from './Forms/GradSunday';
import MassBaptism from './Forms/MassBaptism';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<ScamAlertGreeting />} />
        <Route path="/home" element={<Landing />} />
        <Route path="/become-a-member" element={<BecomeAMember />} />
        <Route path="/ministerial-training" element={<MinisterialTraining />} />
        <Route path="/GradSunday" element={<GradSunday />} />
        <Route path="/EmpowermentSundays" element={<EmpowermentSundays/>}/>
        <Route path="/Events" element={<AllEvents />} />
        <Route path="/Events/FirstSundayBaptism" element={<MassBaptism />} />
        <Route path='/Events/NewMembersClass' element={<NewMembers />} />
        <Route path="Events/JingleAndMingle" element={<MarriedCouples />} />
        <Route path="Events/ConnexChristmas" element={<ConnexChristmas />} />
        <Route path='/Events/21DayFast' element={<HolyConsecration />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact/scam-alert" element={<ScamAlert />} />
        <Route path="/store" element={<Store />} />
        <Route path="/live-stream" element={<LiveStream />} />
        <Route path="/ministries">
          <Route path="MoMENtum" element={<MoMENtum />} />
          <Route path="SacredHearts" element={<SacredHearts />} />
          <Route path="RefresHER" element={<RefresHER />} />
          <Route path="connex" element={<Connex />} />
          <Route path="ChosenYouth" element={<ChosenYouth />} />
          <Route path="SundaySchool" element={<SundaySchool />} />
          <Route path="MusicAndFineArts" element={<MusicAndFineArts />} />
          <Route path="chosen-vessel-everywhere" element={<ChosenVesselEverywhere />} />
        </Route>
        <Route path="/about">
          <Route path="About-Us" element={<AboutUs />} />
          <Route path="Meet-Our-Pastor" element={<MeetOurPastor />} />
          <Route path="Our-People" element={<OurPastoralStaff />} />
        </Route>
        <Route path="/give" element={<Give />} />
        <Route path="/membership-resources">
          <Route path="Ministerial-Alliance" element={<MinisterialAlliance />} />
          <Route path="Giving-Statement-Request" element={<GivingStatementRequest />} />
          <Route path="Volunteer" element={<Volunteer />} />
          <Route path="Image-Awards" element={<ImageAwards />} />
          <Route path="Strengthen-Your-Relationship" element={<StrengthenYourRelationship />} />
        </Route>
        <Route path="/leader" element={<LeadershipPortal />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
