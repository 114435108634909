import React, { useEffect } from 'react';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';

const Store = () => {
  useEffect(() => {
    // Ecwid store script
    const storeScript = document.createElement('script');
    storeScript.type = 'text/javascript';
    storeScript.async = true;
    storeScript.src = 'https://app.ecwid.com/script.js?95684269&data_platform=code&data_date=2023-11-29';
    storeScript.charset = 'utf-8';

    document.getElementById('my-store-95684269').appendChild(storeScript);

    storeScript.onload = () => {
      window.xProductBrowser("categoriesPerRow=3", "views=grid(20,3) list(60) table(60)", "categoryView=grid", "searchView=list", "id=my-store-95684269");
    };

    return () => {
      // Clean up code if needed
    };
  }, []);

  return (
    <>
      <Header />
      <div id="my-store-95684269"></div>
      <div></div>
      <div id="my-search-95684269"></div>
      <div>
        <script data-cfasync="false" type="text/javascript" src="https://app.ecwid.com/script.js?95684269&data_platform=code&data_date=2023-11-29" charset="utf-8"></script>
      </div>
      <Footer />
    </>
  );
};

export default Store;
