import React from 'react';
import '../index.css';
import churchVideo from '../Media/CV-CHURCH.mp4';

function BrowserIntro() {
  const videoStyle = {
    opacity: 0.75, // Set your desired opacity value here (0.5 is 50%)
  };

  return (
    <div className='container-fluid'>
            <div className="video-container">
              <video className="centered-video" autoPlay loop muted playsInline style={videoStyle}>
                <source src={churchVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
    </div>
  );
}

export default BrowserIntro;
