import React from "react";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import "../index.css"; // Import CSS file for styling

// Lesson component to represent each lesson
const Lesson = ({ date, topic, devotional, background }) => (
  <div className="lesson">
    <h3>{date}</h3>
    <p><strong>Lesson Topic:</strong> {topic}</p>
    <p><strong>Devotional Reading:</strong> {devotional}</p>
    <p><strong>Background Scripture:</strong> {background}</p>
    <hr />
  </div>
);

function SundaySchool() {
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime(); // Convert current date to timestamp

  // Define lessons
  const lessons = [
    {
      date: "Sunday, April 21, 2024",
      topic: "Faith Of An Anointer",
      devotional: "Romans 8:1-6",
      background: "Luke 7:36-50"
    },
    {
      date: "Sunday, April 28, 2024",
      topic: "Faith Of An Canaanite",
      devotional: "Psalm 61",
      background: "Matthew 15:21-28"
    },
    {
      date: "Sunday, May 5, 2024",
      topic: "Justified by Faith",
      devotional: "John 3:1-8, 13-17",
      background: "Romans 3:21-30"
    },
    {
      date: "Sunday, May 12, 2024",
      topic: "Counted as Righteous",
      devotional: "Genesis 15:1-6",
      background: "Romans 4"
    },
    {
      date: "Sunday, May 26, 2024",
      topic: "Who Has Believed?",
      devotional: "Deuteronomy 30:11-20",
      background: "Romans 10:1-21"
    },
    {
      date: "Sunday, July 7, 2024",
      topic: "Ceaseless Love",
      devotional: "Psalm 30",
      background: "Lamentations 3:16-24; Psalm 30; Jeremiah 52:1-30"
    },
    {
      date: "Sunday, July 14, 2024",
      topic: "Continual Proclamation",
      devotional: "Ephesians 3:1-13",
      background: "Psalm 71:12-21"
    },
    {
      date: "Sunday, July 21, 2024",
      topic: "Delightful Precepts",
      devotional: "Proverbs 30:1-9",
      background: "Psalm 119:73-80"
    },
    {
      date: "Sunday, July 28, 2024",
      topic: "Expectant Watchfulness",
      devotional: "Matthew 25:1-13",
      background: "Psalm 130"
    }
  ];

  // Find the nearest upcoming lesson
  const upcomingLessons = lessons.filter(lesson => new Date(lesson.date).getTime() > currentTimestamp);

  // Handle the case where no future lessons are found
  const upcomingLesson = upcomingLessons.length > 0 ? upcomingLessons.reduce((prev, curr) => {
    const prevDiff = Math.abs(new Date(prev.date).getTime() - currentTimestamp);
    const currDiff = Math.abs(new Date(curr.date).getTime() - currentTimestamp);
    return prevDiff < currDiff ? prev : curr;
  }) : null;

  return (
    <>
      <Header />
      <div className="content-wrapper">
        <div className="video-lessons-wrapper">
          <div className="container">
            <video controls width="100%" height="auto">
              <source src="https://us06web.zoom.us/j/96203302960" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            {/* Render upcoming lesson */}
            {upcomingLesson ? (
              <Lesson
                date={upcomingLesson.date}
                topic={upcomingLesson.topic}
                devotional={upcomingLesson.devotional}
                background={upcomingLesson.background}
              />
            ) : (
              <p>No upcoming lessons available.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SundaySchool;
