import React from "react";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";

function AboutUs(){
    return(
        <>
        <Header />
        <div className="d-flex flex-column align-items-center">
            <div className="row">
                <div className="d-flex flex-column align-items-center">
                <h1>About Us</h1>
                    <div className="container centered-text">
                        <p>
                            The Chosen Vessel is a dynamic church located in Fort Worth, TX. Our beliefs are based on the Bible and centered on Jesus Christ. Our mission is very simple: to lead people to honor and glorify Jesus Christ. That means we’re not about a building or some complicated religious system, but about helping people from all backgrounds discover the joy that comes from knowing and serving Jesus Christ.
                        </p>
                        <div class="col align-self-center">
                            <h1>
                                Faith
                            </h1>
                            <p>
                                We invite you to come check us out this weekend at one of our worship services. We know that going to a new church can be confusing and intimidating, so we try to do things in a way where you’ll feel welcome.
                            </p>
                            <h1>
                                Family
                            </h1>
                            <p>
                                We value the family and we believe church should be a place for people of all ages. Every Sunday we provide youth ministry alongside our regular services, which provides a age-appropriate environment for youth to encounter Christ.
                            </p>
                            <h1>
                                Leadership
                            </h1>
                            <p>
                                We are guided by a dedicated leadership team of pastors that provide sound, practical, biblical teaching at each worship service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default AboutUs;