import flyer from "../Media/1st Sunday Baptism.jpg"

function IHaveDecided() {
    return (
        <>
            <div className='col-lg'>
                <div className="card transparent-card">
                    <div className="row">
                        <div className="d-flex align-items-center">
                            <div className="col-md-4"> {/* Define the width of the image column */}
                                <div className="d-flex align-items-center">
                                    <img className="card-img-top" src={flyer} alt="1st Sunday Baptism Flyer" />
                                </div>
                            </div>
                            <div className="col-md-8"> {/* Define the width of the description column */}
                                <div className="card-body">
                                    <h3 className="card-title">The Chosen Vessel</h3>
                                    <h5 className="card-title">1st Sunday Baptism</h5>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                            4650 Campus Dr, Fort Worth, TX 76119, USA
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                            Every 1st Sunday after morning worship.
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                            "I Have Decided"
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <a href="/Events/FirstSundayBaptism" className="btn custom-bg-color btn-block rounded-pill">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IHaveDecided;
