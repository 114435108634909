import React from 'react';

function ContactUs() {
    const inputStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        border: 'none',  // Remove border
        borderRadius: '5px', // Add border radius for aesthetics
        padding: '8px', // Add padding for better spacing
        width: '100%' // Make the inputs span the entire width
    };

    return (
        <>
            <div className="d-flex justify-content-center">
                <iframe src="https://thechosenvesselchurch.breezechms.com/form/a9a1c8" width="700" height="800" border="1px solid #ccc" scrolling='no'></iframe>
            </div>
        </>
    );
}

export default ContactUs;
