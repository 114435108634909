import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Countdown() {
    const now = new Date();
    const cdtOffset = -5 * 60; // Central Daylight Time (CDT) offset in minutes (-5 hours)

    const getSundayTarget = (now) => {
        const sundayTarget = new Date(now);
        sundayTarget.setUTCDate(sundayTarget.getUTCDate() + ((7 - sundayTarget.getUTCDay()) % 7));
        sundayTarget.setUTCHours(16, 0, 0, 0); // 11:00 AM CDT is 4:00 PM UTC
        return sundayTarget;
    };

    const getTuesdayTarget = (now) => {
        const tuesdayTarget = new Date(now);
        tuesdayTarget.setUTCHours(0, 0, 0, 0); // Reset to start of the day
        tuesdayTarget.setUTCDate(tuesdayTarget.getUTCDate() + ((2 - tuesdayTarget.getUTCDay() + 7) % 7)); // Set to next Tuesday
        tuesdayTarget.setUTCHours(24 + 1, 30, 0, 0); // 7:30 PM CDT is 1:30 AM UTC next day
        return tuesdayTarget;
    };

    const sundayTarget = getSundayTarget(now);
    const tuesdayTarget = getTuesdayTarget(now);

    const serviceDuration = 2.5 * 60 * 60 * 1000; // 2.5 hours in milliseconds

    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime);

    function calculateRemainingTime() {
        const now = new Date();
        const timeUntilSunday = sundayTarget - now;
        const timeUntilTuesday = tuesdayTarget - now;

        // Check if we are currently within the Sunday service window
        const sundayServiceEnd = sundayTarget.getTime() + serviceDuration;
        const isSundayServiceLive = now >= sundayTarget && now <= sundayServiceEnd;

        // Check if we are currently within the Tuesday service window
        const tuesdayServiceEnd = tuesdayTarget.getTime() + serviceDuration;
        const isTuesdayServiceLive = now >= tuesdayTarget && now <= tuesdayServiceEnd;

        if (isSundayServiceLive || isTuesdayServiceLive) {
            return -1; // Indicate that the event is live
        }

        // If the target time is in the past, add 7 days to it
        let adjustedTimeUntilSunday = timeUntilSunday;
        if (timeUntilSunday < 0) {
            adjustedTimeUntilSunday += 7 * 24 * 60 * 60 * 1000;
        }

        let adjustedTimeUntilTuesday = timeUntilTuesday;
        if (timeUntilTuesday < 0) {
            adjustedTimeUntilTuesday += 7 * 24 * 60 * 60 * 1000;
        }

        return Math.min(adjustedTimeUntilSunday, adjustedTimeUntilTuesday);
    }

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setRemainingTime(calculateRemainingTime());
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [sundayTarget, tuesdayTarget]);

    const eventIsLive = remainingTime === -1;

    const countdownStyle = {
        top: '0',
        left: '0',
        right: '0',
        backgroundColor: 'rgba(96,15,216)',
        zIndex: '1000', // Ensure the countdown is above the navigation
    };

    const linkStyle = {
        textDecoration: 'none', // Remove the underline
        color: 'white', // Set the text color to white
    };

    return (
        <div>
            <Link to="/Live-Stream" className="d-block p-2 text-white" style={Object.assign({}, countdownStyle, linkStyle)}>
                <div className='d-flex justify-content-center'>
                    {eventIsLive ? "We're Live Now" : `We're Live In ${formatRemainingTime(remainingTime)}`}
                </div>
            </Link>
        </div>
    );
}

function formatRemainingTime(remainingTime) {
    if (remainingTime < 0) {
        return '';
    }
    
    const hours = Math.floor(remainingTime / 3600000);
    const minutes = Math.floor((remainingTime % 3600000) / 60000);
    const seconds = Math.floor((remainingTime % 60000) / 1000);

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
}

export default Countdown;
