import React, { useState } from 'react';
import eventCards from './MasterEvents';

function Events() {
  const initialEvents = eventCards.slice(0, 3); // Extracting the first three events
  const [expandedEvents, setExpandedEvents] = useState(Array(initialEvents.length).fill(false));

  const toggleExpansion = (index) => {
    const updatedExpandedEvents = [...expandedEvents];
    updatedExpandedEvents[index] = !updatedExpandedEvents[index];
    setExpandedEvents(updatedExpandedEvents);
  };

  return (
    <>
      <hr />
      <div className="container"> {/* Container for all event cards */}
        <div className="d-flex justify-content-center">
          {initialEvents.map((event, index) => (
            <div className="col" key={index} style={{ flex: '0 0 auto', maxWidth: '25rem', margin: '0.5rem' }}>
              <div className="card custom-card">
                <img src={event.imgSrc} className="card-img-top" alt="Event" />
                <div className="card-body">
                  <div className='d-flex justify-content-center'>
                    <h8 className="card-title" style={{ color: 'white', textAlign: 'center' }}><b>{event.title}</b></h8>
                  </div>
                  <p style={{ color: 'white' }}><i>{event.text}</i></p>
                  {event.link ? (
                    // If there's a link, render the "Read More" button to toggle expansion
                    !expandedEvents[index] ? (
                      <button onClick={() => toggleExpansion(index)} className="btn custom-bg-color btn-block rounded-pill"><b>Read More</b></button>
                    ) : (
                      <>
                        <p className="card-text">{event.eventDescription}</p>
                        <a href={event.link} className="btn custom-bg-color btn-block rounded-pill"><b>Register Now</b></a>
                      </>
                    )
                  ) : (
                    // If there's no link, redirect to "/events" when clicked
                    <button onClick={() => window.location.href = "/events"} className="btn custom-bg-color btn-block rounded-pill">
                      <b>{expandedEvents[index] ? 'Show Less' : 'Read More'}</b>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Events;
