import React, { useState } from 'react';
import flyer from "../Media/1st Sunday Baptism.jpg"

function DecidedMobile() {

    const [isExpanded1, setIsExpanded1] = useState(false);
    const toggleExpansion1 = () => {
        setIsExpanded1(!isExpanded1);
    };

    return (
        <>
            <div className='col-lg'>
                <div className="card transparent-card">
                    <div className="d-flex align-items-center">
                        <img className="card-img-top" src={flyer} alt="Card image cap" />
                    </div>
                    <div className="row">
                        <div className="d-flex align-items-center">
                            <div className="card-body">
                                <h3 className="card-title">The Chosen Vessel</h3>
                                <h5 className="card-title">1st Sunday Baptism</h5>
                                <div className='d-flex justify-content-center'>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p>
                                        Every 1st Sunday after morning worship
                                        4650 Campus Dr, Fort Worth, TX 76119, USA
                                    </p>
                                </div>
                                {isExpanded1 ? (
                                    <>
                                        <div className='d-flex justify-content-center'>
                                            <p>
                                                "I Have Decided"
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <a href="/Events/FirstSundayBaptism" className="btn custom-bg-color btn-block rounded-pill">Register Now</a>
                                        </div>
                                    </>
                                ) : (
                                    <button onClick={toggleExpansion1} className="btn custom-bg-color btn-block rounded-pill"><b>Read More</b></button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DecidedMobile;
