import Header from "../Landing/Header";
import Footer from "../Landing/Footer";

function MinisterialTraining() {
    return (
        <>
            <Header />
            <iframe
                src="https://thechosenvesselchurch.breezechms.com/form/1b3d0b"
                width="100%"
                height="1000px"
                style={{
                    border: '0',
                    background: 'black',
                }}
                title="Fall Churchwide Cleanup"
            ></iframe>
            <Footer />
        </>
    )
}

export default MinisterialTraining;