import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import slide3 from '../Media/CVEverywhere.jpg';
import Banner from "../Media/BANNERS/CVE.png";
import MobileBanner from "../Media/BANNERS/Mobile/CVE.png";

function ChosenVesselEverywhere() {

  const screenWidth = window.innerWidth;
  const thresholdWidth = 640;
  const shouldShowMobile = screenWidth < thresholdWidth;

  const pageStyle = {
    backgroundColor: '#0e0b16',
    color: '#fff',
    fontFamily: "'Poppins', sans-serif",
    lineHeight: '1.8',
    padding: '20px',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  const contentStyle = {
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const headerStyle = {
    color: '#ffd700',
    fontSize: '2.5em',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const eventDetailsStyle = {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#1a1a2e',
    borderRadius: '12px',
    width: '100%',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',
    textAlign: 'left',
  };

  const hotelInfoStyle = {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#2b2b3a',
    borderRadius: '12px',
    width: '100%',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',
    textAlign: 'left',
  };

  const linkStyle = {
    color: '#4dd2ff',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <>
      <Header />
      <div style={pageStyle} className="container-fluid">
        {shouldShowMobile ? (
          <div className="container">
            <img src={MobileBanner} className="img-fluid rounded shadow" alt="Mobile Banner" />
          </div>
        ) : (
          <img src={Banner} className="img-fluid rounded shadow mb-4" alt="Banner" />
        )}
        <div style={contentStyle}>
          <video controls className="img-fluid rounded shadow mb-4">
            <source src="https://video.wixstatic.com/video/186f81_480f8c14b6e541ed960ecc0a68ec4251/1080p/mp4/file.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <h1 style={headerStyle}>2024 Chosen Vessel Everywhere Weekend</h1>
          <p className="lead mb-4"><strong>Dates:</strong> Friday, October 25, 2024 to Sunday, October 27, 2024</p>

          <p className="highlight" style={{ color: '#ffd700', fontSize: '1.2em' }}>Registration is required for our 2024 Chosen Vessel Everywhere Weekend and includes:</p>
          <ul className="list-unstyled">
            <li>2024 CVE Welcome Gift</li>
            <li>Saturday's Evening of Family Fun</li>
            <li>Sunday CVE Fellowship Meal</li>
          </ul>

          <div style={eventDetailsStyle} className="text-start">
            <h2 style={{ color: '#f8c471' }}>Saturday, October 26, 2024 - Chosen Vessel Everywhere - Evening of Family Fun</h2>
            <p>Food, Fellowship & Fun with Arcade Games, Tabletop, Air Hockey, Ping Pong, Billiards, and More</p>
          </div>

          <div style={eventDetailsStyle} className="text-start">
            <h2 style={{ color: '#f8c471' }}>Sunday, October 27, 2024 - In Person Sunday Morning Worship Experience @ Chosen Vessel Church</h2>
            <ul className="list-unstyled">
              <li>Sunday School</li>
              <li>Campus Tour & Chosen Vessel Leaders' Meet & Greet</li>
              <li>Sunday Morning Live Worship Experience</li>
              <li>Chosen Vessel Everywhere Fellowship Meal</li>
            </ul>
          </div>

          <div style={eventDetailsStyle} className="text-start">
            <h2 style={{ color: '#f8c471' }}>Bonus Event - Sunday, October 27, 2024 @ 7:00 pm</h2>
            <p>Reunion Tour Concert featuring Bishop Marvin Sapp, Kirk Franklin, Yolanda Adams, Clark Sisters, and Fred Hammond</p>
            <p>Tickets Available For Purchase On Ticketmaster.</p>
          </div>

          <div style={hotelInfoStyle} className="text-start">
            <h2 style={{ color: '#ffd700' }}>Discounted Hotel Accommodations</h2>
            <p><strong>Location:</strong> Hilton Garden Inn Dallas/Arlington South</p>
            <p>521 East Interstate 20, Arlington, TX 76018</p>
            <p><strong>Courtesy Room Block with a Discounted Room Rate:</strong></p>
            <ul className="list-unstyled">
              <li>King Room: $149 + tax (published room rate is currently $158+tax)</li>
              <li>2 Queen Beds: $159 + tax (published room rate is $172+tax)</li>
            </ul>
            <p>Attendees can call <strong>1-800-HILTONS</strong> to book their accommodations using the code: <strong>CMW24</strong> or use the following link: <a href="https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=DALCXGI&groupCode=CMW24&arrivaldate=2024-10-25&departuredate=2024-10-28&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT" style={linkStyle}>2024 Cyber Member Weekend ~ Hotel Accommodations</a></p>
            <p><strong>Group cut-off date:</strong> October 4, 2024 to take advantage of this discounted rate</p>
          </div>

          <div className="footer mt-4" style={{ fontSize: '0.9em', color: '#7f8c8d' }}>
            <p>For more information, please contact us at info@chosenvessel.org</p>
          </div>

          <div className="footer mt-4" style={{ fontSize: '0.9em', color: '#7f8c8d' }}>
            <p>Click the Button Below To Register</p>
            <button data-brushfire="ccc8e9d6-7087-40bd-9d23-30335bd72576" class="brushfire-button register blue"></button>
          </div>

          <br/>

          {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <iframe src="https://thechosenvesselchurch.breezechms.com/form/572ddb" width="700" height="750" className="border rounded shadow" scrolling="no"></iframe>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChosenVesselEverywhere;
