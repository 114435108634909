function Map() {
    const iframeStyle = {
        border: '0',
        width: '600px',
        height: '20rem',
        allowFullscreen: '',
        loading: 'lazy',
        referrerPolicy: 'no-referrer-when-downgrade'
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="row">
                <div className="d-flex flex-column align-items-center">
                    <h1>Service Times</h1>
                    <p>Sunday</p>
                    <p>9:30am - 10:30am CST | Virtual Sunday School</p>
                    <p>11:00am CST | Live Worship</p>
                    <p>11:00am CST | Live Youth Church</p>
                    <p>Tuesday Night Live</p>
                    <p>7:30pm CST | Virtual</p>
                    <p>The Chosen Vessel</p>
                    <p>info@thechosenvessel.org | 817-413-9849</p>
                    <p>To contact us, please simply complete the online form above.</p>
                    <p>(Please allow 1-2 business days for a response.)</p>
                </div>
            </div>
            <div className="row">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2198.6297200490762!2d-97.29889787221467!3d32.67901776131979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e6fd0afe75e29%3A0xc1404f99f79458d!2sThe%20Chosen%20Vessel%20Cathedral!5e0!3m2!1sen!2sus!4v1693149643577!5m2!1sen!2sus"
                    style={iframeStyle}
                />
            </div>
        </div>
    );
}

export default Map;
