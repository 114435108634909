import React from "react";
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";
import ContactUs from "../Components/ContactUs";
import Map from "../Components/Map";
import Banner from "../Media/BANNERS/contact.png";
import MobileBanner from "../Media/BANNERS/Mobile/contact.png";

function Contact() {

      // Get the current width of the viewport
      const screenWidth = window.innerWidth;

      // Define a threshold width (540 in this case)
      const thresholdWidth = 640;
  
      // Determine whether to show the intro or slideshow based on screen width
      const shouldShowMobile = screenWidth < thresholdWidth;

  return (
    <>
      <Header />
      {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
      <ContactUs />
      <Map />
      <Footer />
    </>
  );
}

export default Contact;
