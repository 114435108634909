import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Fast from "../Media/Holy Consecration 2024 - 21 Day Consecration Fast.jpg";
import Purpose from "../Media/Holy Consecration 2024 - 21 Day Purpose.jpg";

function HolyConsecration() {
    return (
        <>
            <Header />
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-center">
                        <div class="col-lg">
                            <img src={Fast} className="card-img-top" alt="..." />
                            <img src={Purpose} className="card-img-top" alt="..." />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default HolyConsecration;
