import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from "../Media/BANNERS/volunteer.png";
import MobileBanner from "../Media/BANNERS/Mobile/volunteer.png"

function Volunteer() {

    const inputStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        border: 'none',  // Remove border
        borderRadius: '5px', // Add border radius for aesthetics
        padding: '8px', // Add padding for better spacing
        width: '100%' // Make the inputs span the entire width
    };

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />
            <br />
            <div className="d-flex flex-column align-items-center">
                <div className="row">
                    <div className="d-flex flex-column align-items-center">
                        <div className="col-7">
                            <div className="d-flex flex-column align-items-center">
                                <h3 style={{ color: 'white' }}>
                                    Parking Lot Attendants
                                </h3>
                                <p>
                                    This position is the first position our guest and members experience.
                                    This position requires a pleasant demeanor.
                                    Parking lot attendants will ensure the flow of traffic is safe for all guest and members.
                                </p>
                                <h3 style={{ color: 'white' }}>
                                    Brother Servants-Gateway Position
                                </h3>
                                <p>
                                    Vision: To be physically present for, engaged in and leading the saints in worship.
                                    Identified by the Senior Pastor as the ministry team responsible for all traffic inside of the sanctuary.
                                    Brother Servants are spiritually alert and responsive, well equipped and uniformly operate.
                                </p>
                                <p>
                                    This position is the second position our guest and members experience. This position requires an outgoing person with a very pleasant demeanor. It also requires someone very disciplined in their flesh. Someone who can comfort the rude, encourage the downcast and greet the grumpy. This requires a gift. This requires advance prayer for the spirit of God to equip you with what you need to draw those who need to come but their flesh is trying to convince them they are unwelcome.
                                </p>
                                <ul>
                                    <li style={{ color: 'white' }}>
                                        Brother servants do not pass messages to the pulpit during worship. CMS or lead brother servant should handle any request and direct to concierge on duty or director as needed.
                                    </li>
                                    <li style={{ color: 'white' }}>
                                        Brother servants encourage the men to sit on the front row and avoid having empty rows after praise and worship.
                                    </li>
                                    <li style={{ color: 'white' }}>
                                        During special events or services, reserved seats will be released after praise and worship with exception of the front row and 4 seats on the second row.
                                    </li>
                                </ul>
                                <h3 style={{ color: 'white' }}>
                                    Outreach and Evangelism
                                </h3>
                                <p>
                                    Vision: Responsible for identifying resources and information that can be made available to members on a voluntary basis.
                                </p>
                                <ul>
                                    <li style={{ color: 'white' }}>
                                        Will organize outreach information seminars.
                                    </li>
                                    <li style={{ color: 'white' }}>
                                        Will ensure that the ministry team is working within established guidelines for lay recovery team members.
                                    </li>
                                </ul>
                                <h3 style={{ color: 'white' }}>
                                    Campus Enhancement
                                </h3>
                                <p>
                                    Insures our outside grounds are beautified with flowers, shrubs, mulch, or other appropriate items.
                                    A campus enhancement also prepares our sanctuary and other areas that will be used on Sunday.
                                </p>
                                <h3 style={{ color: 'white' }}>
                                    Visions Bookstore
                                </h3>
                                <p>
                                    Our mission is to build community within the church, create fellowship and enrich lives through reading;
                                    We are a place where members and guest alike can meet with friends and chat over a cup of coffee, purchase an occasional card, browse through the selection of books or find that t-shirt that fits just right.
                                    If you love people, books and/or coffee and are looking for a fun place to serve, our team might be the place for you.
                                </p>
                                <h3 style={{ color: 'white' }}>
                                    Audio/Video Media Ministry
                                </h3>
                                <h5 style={{ color: 'white' }}>
                                    TEAM VISION & GOAL
                                </h5>
                                <p>
                                    Facilitate and amplify excellent, distraction-free experiences in hopes to enhance encounters with God during services.
                                </p>
                                <h5 style={{ color: 'white' }}>
                                    TEAM ROLE
                                </h5>
                                <p>
                                    To serve diligently behind the scenes to support The Chosen Vessel’s pastors, worship teams, and auxiliaries who serve on stage in any capacity of song, message, or video. Through the serving of the media ministry, members and guests who physically or virtually present, are able to hear the truth of God’s Word and experience God in a unified worship environment.
                                </p>
                                <h3 style={{ color: 'white' }}>
                                    Preparing The Elements
                                </h3>
                                <p>
                                    We serve to create a unified worship experience through technical elements/equipment which include, but are not limited to the following:
                                </p>
                                <ul>
                                    <li style={{ color: 'white' }}>
                                        Operating camera and video equipment
                                    </li>
                                    <li style={{ color: 'white' }}>
                                        Projecting Scripture, song lyrics, or announcements on projection screens during services
                                    </li>
                                    <li style={{ color: 'white' }}>
                                        Operating sound reinforcement equipment
                                    </li>
                                    <li style={{ color: 'white' }}>
                                        Changing the atmosphere with lighting adjustments and/or equipment
                                    </li>
                                </ul>
                                <p>
                                    The intention is to assist in providing an atmosphere that enable all that enter the environment we serve to encounter and grow in God.
                                </p>
                                <h3 style={{ color: 'white' }}>
                                    Creating The Environment
                                </h3>
                                <p>
                                    We have the honor & privilege of enhancing the Spirit-filled environment of worship in our church through video, sound, lighting, and general atmosphere. We are charged to protect this environment by keeping it distraction-free to the best of our ability; while maintaining a spirit of excellence and authenticity.
                                </p>
                                <iframe src="https://thechosenvesselchurch.breezechms.com/form/3eb46c" width="700" height="1300" border="1px solid #ccc" scrolling='no'></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Volunteer;