import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from '../Media/BANNERS/CONNEX.png';
import MobileBanner from "../Media/BANNERS/Mobile/connex.png";

function Connex() {

    const inputStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        border: 'none',  // Remove border
        borderRadius: '5px', // Add border radius for aesthetics
        padding: '8px', // Add padding for better spacing
        width: '100%' // Make the inputs span the entire width
    };

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />
            <br />
            <div className="d-flex justify-content-center">
                <div className="col-lg-6">
                    <div class="container-sm">
                    <iframe src="https://thechosenvesselchurch.breezechms.com/form/71551d" width="700" height="1400" border="1px solid #ccc" scrolling='no'></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Connex;