import Footer from "../Landing/Footer";
import Header from "../Landing/Header";

function EmpowermentSundays(){
    return(
        <>
            <Header />
            <iframe
                src="https://thechosenvesselchurch.breezechms.com/form/8cefc8"
                width="100%"
                height="1000px"
                style={{
                    border: '0',
                    background: 'black',
                }}
                title="Fall Churchwide Cleanup"
            ></iframe>
            <Footer />
        </>
    )
}

export default EmpowermentSundays;