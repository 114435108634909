import React from "react";
import Navigation from "../Components/Navigation";
import Countdown from "../Components/Countdown";

function Header() {
    return (
        <>
        <Navigation />
        </>
    );
}

export default Header;
