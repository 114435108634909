import Empowerment from "../Media/Main Graphic - EMPOWERMENT SUNDAYS.jpg";
import September2Remember from "../Media/September2Remember.jpg";
import OctoberTakeover from "../Media/OctoberTakeoverTCVC.jpg";
import WeStillDo from "../Media/WeStillDoWeekend.jpg";
import SilverSaintsFellowship from "../Media/SilverSaintsFellowship.png";
import SilverSaintsSessions from "../Media/SilverSaintsSessions.png";
import CyberMemberWeekend from "../Media/CyberMember.jpg";


const eventCards = [

    {
        title: "We Still Do Weekend",
        text: "August 23-25",
        month: "August",
        day: "23-25",
        eventDescription: "Celebrate your love and commitment at The Chosen Vessel Church's 'We Still Do' Weekend from August 23-25, 2024. Join us for a special time of connection and fun, with events including heartfelt discussions, playful activities, and a beautiful renewal ceremony—all for just $40 per couple",
        imgSrc: WeStillDo,
        link: "https://brushfire.com/tcvc/westilldoweekend/583060",
    },
    {
        title: "Silver Saints Sessions",
        text: "Starting September",
        month: "September",
        day: "10",
        eventDescription: `On Tuesday, September 10, 2024, at 1:00 pm, there will be a gathering at Lady Ar's Cafe, located in Chosen Vessel's Community Life & Learning Center. The discussion topic will be "Are You Prepared?" focusing on advance planning and final expenses. Join us for an important conversation.`,
        imgSrc: SilverSaintsSessions,
        link: "https://thechosenvesselchurch.breezechms.com/form/8cefc87291",
    },
    {
        title: "September To Remember",
        text: "September 1 - 29",
        month: "September",
        day: "1-29",
        eventDescription: "Join us this September at The Chosen Vessel Church as we celebrate our 104th Church Anniversary with a month of powerful services, both in-person and online. Experience inspiring messages from anointed speakers including Dr. Valerie Moore, Dr. Wanda Frazier-Parker, Bishop Marc House, Bishop Darius Nixon, and Pastor Erick Bowens.",
        imgSrc: September2Remember,
    },
    {
        title: "October Takeover",
        text: "October 6 - 27",
        month: "October",
        day: "6-27",
        eventDescription: "Join us this October for an impactful month of worship and inspiration at Chosen Vessel Church's October Takeover, featuring powerful messages from esteemed pastors and bishops. Experience the anointing in-person or online every Sunday at 11:00 AM CST from October 6th to October 27th",
        imgSrc: OctoberTakeover,
    },
    {
        title: "Cyber Member Weekend",
        text: "October 26-27",
        month: "October",
        day: "26th-27th",
        eventDescription: "The Chosen Vessel Everywhere Weekend, happening on October 26-27, invites all online members to join in person for a night of fellowship at Dave & Buster's, followed by worship and a family dinner. The event will culminate with a special Reunion Tour featuring Kirk Franklin, Yolanda Adams, Marvin Sapp, Clark Sisters, and Fred Hammond. CLICK FLYER FOR MORE INFORMATION",
        imgSrc: CyberMemberWeekend,
        link: "/ministries/Chosen-Vessel-Everywhere",
    },
    {
        title: "Empowerment Sundays At the Vessel",
        text: "Starting February",
        month: "Feb",
        eventDescription: "Growth Connect Class for Ages 18-45 at our Main Campus in Classroom A-7: Attendees should use our front entrance and our Greeters & Membership Services will be available to direct you. Wisdom In The Word Class for Ages 46 and Up at our Main Campus in our Bishop R E Young Classroom: Attendees should use our front entrance and our Greeters & Membership Services will be available to direct you. Walking Through The Word for  Ages 18 and Up in our Virtual Classroom: Attendees should join us by clicking the flyer and being taken to the meeting.",
        imgSrc: Empowerment,
        link: "/ministries/SundaySchool",
    },
];

export default eventCards;
