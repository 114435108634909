import flyer from "../Media/Next Steps - New Members Revised Flyer.jpg"

function NewMembersClassDesk() {
    return (
        <>
            <div className='col-lg'>
                <div className="card transparent-card">
                    <div className="row">
                        <div class="d-flex align-items-center">
                            <div className="col-md-8"> {/* Define the width of the description column */}
                                <div className="card-body">
                                    <h3 className="card-title">The Chosen Vessel</h3>
                                    <h5 className="card-title">Next Steps Class</h5>
                                    <div className='d-flex justify-content-center'>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                            The Chosen Vessel, 4650 Campus Dr, Fort Worth, TX 76119, USA
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>
                                            The Chosen Vessel Church is excited to offer our Next Steps Class, whether you're new to our community or have been with us for a while, this class is designed to help you get to know us better and understand how you can get involved.
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <a href="/Events/NewMembersClass" className="btn custom-bg-color btn-block rounded-pill">Register Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4"> {/* Define the width of the image column */}
                                <div class="d-flex align-items-center">
                                    <img className="card-img-top" src={flyer} alt="Card image cap" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewMembersClassDesk;