import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from "../Media/BANNERS/ChosenYouth.png";
import MobileBanner from "../Media/BANNERS/Mobile/chosenYouth.png";

function ChosenYouth() {

  const inputStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',  // Remove border
    borderRadius: '5px', // Add border radius for aesthetics
    padding: '8px', // Add padding for better spacing
    width: '100%' // Make the inputs span the entire width
  };

  // Get the current width of the viewport
  const screenWidth = window.innerWidth;

  // Define a threshold width (540 in this case)
  const thresholdWidth = 640;

  // Determine whether to show the intro or slideshow based on screen width
  const shouldShowMobile = screenWidth < thresholdWidth;

  return (
    <>
      <Header />
      {shouldShowMobile ? (
        <div className="container">
          <img src={MobileBanner} className="card-img-top" alt="..." />
        </div>
      ) : (
        <img src={Banner} className="card-img-top" alt="..." />
      )}
      <br />
      <div className="row">
        <div className="d-flex justify-content-center">
          <div className="col-md-10">
            <div className="d-flex justify-content-center">
              <h1 style={{ color: 'white' }}>Want To Get Your Youth Involved?</h1>
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-md-auto">
                <iframe src="https://thechosenvesselchurch.breezechms.com/form/b15af2" width="700" height="600" border="1px solid #ccc" scrolling='no'></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ChosenYouth;