import Footer from "../Landing/Footer";
import Header from "../Landing/Header";

function NotFound(){
    return(
        <>
        <Header />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>
            <div className="d-flex justify-content-center">
                <h1>404 - Not Found</h1>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
        </>
    )
}

export default NotFound;