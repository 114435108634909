import Footer from "../Landing/Footer";
import Header from "../Landing/Header";
import Banner from "../Media/Become A Member.png";
import MobileBanner from "../Media/BAMMobile.png";

function BecomeAMember(){
              // Get the current width of the viewport
              const screenWidth = window.innerWidth;

              // Define a threshold width (540 in this case)
              const thresholdWidth = 640;
          
              // Determine whether to show the intro or slideshow based on screen width
              const shouldShowMobile = screenWidth < thresholdWidth;
    return(
        <>
        <Header />
        {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <div className="row">
          <div className="d-flex justify-content-center">
            <div className="col-md-10">
              <div className="d-flex justify-content-center">
                <iframe src="https://thechosenvesselchurch.breezechms.com/form/354dba" width="700" height="1000" border="1px solid #ccc" scrolling='no'></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        </>
    )
}

export default BecomeAMember;