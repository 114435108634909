import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import Banner from "../Media/BANNERS/RefresHER.png";
import MobileBanner from "../Media/BANNERS/Mobile/refresher.png";

function RefresHER() {

    const inputStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        border: 'none',  // Remove border
        borderRadius: '5px', // Add border radius for aesthetics
        padding: '8px', // Add padding for better spacing
        width: '100%' // Make the inputs span the entire width
    };

    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (540 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowMobile = screenWidth < thresholdWidth;

    return (
        <>
            <Header />
            {shouldShowMobile ? (
                <div className="container">
                    <img src={MobileBanner} className="card-img-top" alt="..." />
                </div>
            ) : (
                <img src={Banner} className="card-img-top" alt="..." />
            )}
            <br />
            <br />
            <div className="d-flex justify-content-center">
                <div className="col-sm-6">
                    <p>
                        RefresHER Women's Ministry is a vibrant and dynamic community within our church that draws inspiration from Jeremiah 31:25. We are dedicated to providing a space where women of all walks of life can come together to find spiritual renewal, support, and refreshment.
                    </p>
                    <p>
                        Just as God promises in Jeremiah, RefresHER is committed to being a source of refreshment for the weary souls and a place of satisfaction for those in need of spiritual nourishment. Our ministry is designed to help women grow in their faith, foster meaningful connections, and experience the abundant life that Christ offers.
                    </p>
                    <div className="d-flex justify-content-center">
                        <h3 style={{ color: 'white' }}>
                            Our Mission
                        </h3>
                    </div>
                    <p>
                        At RefresHER Women's Ministry, our mission is to provide a welcoming and nurturing community where women can find spiritual refreshment, grow in their faith, and build meaningful connections. Guided by the promise of Jeremiah 31:25, we seek to be a source of renewal for the weary and a place of satisfaction for the faint of heart. Our purpose is to empower women to deepen their relationship with God, support one another in love, and actively serve our church and community. Through Bible study, fellowship, prayer, mentorship, and service, we aspire to help women discover the rest and fulfillment that come from a vibrant connection with Christ. Together, we journey towards a richer, more fulfilling faith life, embracing the abundant love and grace of our Lord
                    </p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <h3 style={{ color: 'white' }}>
                    Join US Today:
                </h3>
            </div>
            <br />
            <div className='d-flex justify-content-center'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center'>
                        <p>
                            Join us in this exciting journey of faith, sisterhood, and spiritual growth. RefresHER is not just a ministry; it's a movement, and we invite you to be a part of it. Together, we will rise, unshaken, in pursuit of God's purpose for our lives.
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-md-auto">
                    <iframe src="https://thechosenvesselchurch.breezechms.com/form/36667b" width="700" height="500" border="1px solid #ccc" scrolling='no'></iframe>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RefresHER;